import { getNestedStylesByKey, ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { AccordionStyles } from '@/app/stylesheets'
import { AppIcon, variantProvider } from '@/app'
import { View, Button } from '@/components'
import { PropsWithChildren } from 'react'
import { AccordionComposition } from '@/app/stylesheets/Accordion'

export type AccordionProps = PropsWithChildren & ComponentVariants<typeof AccordionStyles>
  & {
    styles?: StylesOf<AccordionComposition>
    title: string
    initiallyOpen?: boolean
    visible?: boolean
    onPress?: () => void
  }

export const Accordion = ({
  variants = [],
  styles = {},
  responsiveVariants = {},
  title,
  children,
  visible,
  onPress,
  ...rest
}: AccordionProps) => {

  const variantStyles = useDefaultComponentStyle<'u:Accordion', typeof AccordionStyles>('u:Accordion', {
    variants,
    rootElement: 'wrapper',
    styles,
    responsiveVariants,
  })

  const getStyles = (key: AccordionComposition) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  return (
    <View style={getStyles('wrapper')} {...rest}>
      <Button
        onPress={onPress}
        style={getStyles('header')}
        styles={{
          text: variantStyles.headerText,
          rightIcon: variantStyles.headerRightIcon,
        }}
        rightIcon={visible ? 'chevron-up' : 'chevron-down' as AppIcon}
        debugName='Accordion Button'
        text={title}
      />
      <View
        style={getStyles('content')}
        css={visible ? _styles.content : _styles.contentHidden}
      >
        {children}
      </View>
    </View>
  )
}

const _styles = variantProvider.createComponentStyle((theme) => ({
  content: {
    display: 'flex',
  },
  contentHidden: {
    display: 'none',
  },
}), true)
