import { useState } from 'react'
import { ComponentVariants, PropsOf, StylesOf, TypeGuards, onUpdate, useDefaultComponentStyle } from '@codeleap/common'
import { View, FilterButton, Text } from '@/components'
import { CourseUtils, convertParamsToRange, convertRangeToParams } from '@/utils'
import { CourseFiltersStyles } from '@/app/stylesheets'
import { CourseFiltersComposition } from '@/app/stylesheets/CourseFilters'
import { CourseFilterOption, CustomCourseFilterOption, MaxMinFilter } from '@/utils/courses/filters'

export type CourseFiltersProps = Omit<PropsOf<typeof View>, 'variants' | 'styles'> & {
  params: Record<string, string>
  onSeeResults?: () => void
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
  results?: number
  isLoading?: boolean
  onApply?: () => void
  styles?: StylesOf<CourseFiltersComposition>
  filterOptions?: (CourseFilterOption | CustomCourseFilterOption)[]
} & ComponentVariants<typeof CourseFiltersStyles>

export type CourseFiltersModalFilters = 'paved' | 'hilly' | 'elevation' | 'ascent' | 'distance' | 'sort_by'

const CourseFilter = ({
  filter,
  filterVisible,
  handleClear,
  setFilterVisible,
  handleFilterPress,
  handleChange,
  params,
  ...rest
}) => {
  const [value, setValue] = useState(filter.value)
  const [hasChanges, setHasChanges] = useState(false)
  const { key, defaultValue, min, max, suffix = '', label, unlimited = false } = filter
  const _value = value || defaultValue
  const visible = filterVisible === key
  const hasFilter = filter.value !== null
  let minValue = TypeGuards.isNil(_value[0]) ? min : _value[0]
  let maxValue = TypeGuards.isNil(_value[1]) ? max : _value[1]
  const minTrack = `${minValue}${suffix}`
  let maxTrack = `${maxValue}${suffix}`

  if (maxValue >= max && unlimited) {
    maxTrack = `${max}+ ${suffix}`
    maxValue = max
  }

  if (minValue >= max && unlimited) {
    maxTrack = `${minValue}+ ${suffix}`
    minValue = max
  }

  const toggle = () => setFilterVisible(null)

  const range = convertParamsToRange(params, key)

  const rangeToCompare = TypeGuards.isNull(range) ? defaultValue : range
  const hasValueChanged = !value?.every((v, index) => v === rangeToCompare?.[index])

  function resetSliderValue() {
    setValue(defaultValue)
  }

  onUpdate(() => {
    if (!visible && hasChanges) {
      setValue(filter.value)
      setHasChanges(false)
    }
  }, [visible, filter.value, filter.defaultValue])

  onUpdate(() => {
    if (TypeGuards.isNil(params) && !visible) {
      resetSliderValue()
    }
  }, [params])

  const disableApply = !hasValueChanged || (TypeGuards.isNull(value) && TypeGuards.isNull(range))

  return (
    <FilterButton
      {...rest}
      id={`filter-button-${key}`}
      disableApply={disableApply}
      debugName='Course:FilterButton'
      onClear={() => {
        handleClear(key)
        setValue(defaultValue)
        setHasChanges(false)
        toggle()
      }}
      visible={visible}
      toggle={toggle}
      buttonProps={{
        icon: hasFilter ? 'badge' : null,
        text: label,
        debugName: `Explore:FilterButton:${key}`,
        onPress: () => handleFilterPress(key),
      }}
      onApply={() => {
        handleChange(key, value)
        setHasChanges(false)
        toggle()
      }}
      sliderProps={{
        ...filter,
        key: key,
        debugName: `Explore:Slider:${key}`,
        value: [minValue, maxValue],
        minStepsBetweenThumbs: 0,
        onValueChange: (newValue) => {
          setValue(newValue)
          setHasChanges(true)
        },
        trackMarks: {
          [min]: minTrack,
          [max]: maxTrack,
        },
      }}
    />)
}

export const CourseFilters = (props: CourseFiltersProps) => {
  const {
    params,
    onApply,
    onSeeResults,
    setParams,
    results,
    isLoading,
    variants,
    responsiveVariants,
    styles,
    style,
    filterOptions = CourseUtils.getCourseFilterOptions(params),
  } = props

  const variantStyles = useDefaultComponentStyle<
    'u:CourseFilters',
    typeof CourseFiltersStyles
  >('u:CourseFilters', {
    variants,
    styles,
    responsiveVariants,
  })

  const [filterVisible, setFilterVisible] = useState(null)

  const handleChange = (key: CourseFiltersModalFilters, value: MaxMinFilter) => {

    setParams(state => {
      const { unlimited, max } = filterOptions.find(filter => filter.key === key) as CourseFilterOption
      const isMaxReached = value?.[1] >= max
      const isUnlimited = isMaxReached && unlimited

      const _value = isUnlimited ? [value[0], null] : value

      const result = convertRangeToParams(_value as MaxMinFilter, key)
      return { ...state, ...result }
    })

    onApply?.()
  }

  const handleFilterPress = (key: CourseFiltersModalFilters) => {
    setFilterVisible(key)
  }

  const handleClear = (key: CourseFiltersModalFilters) => {
    setParams(state => {
      state[`min_${key}`] = null
      state[`max_${key}`] = null
      return { ...state }
    })
  }

  return (
    <View css={[variantStyles.outerWrapper, style]}>
      <View css={[variantStyles.wrapper]}>
        {filterOptions.map((filter) => {
          if ('customFilter' in filter) return filter.customFilter({ filterVisible, setFilterVisible, params, setParams })
          return (
            <CourseFilter
              filter={filter}
              key={filter.key}
              onSeeResults={onSeeResults}
              filterVisible={filterVisible}
              setFilterVisible={setFilterVisible}
              handleClear={handleClear}
              handleFilterPress={handleFilterPress}
              handleChange={handleChange}
              params={params}
            />
          )
        })}
      </View>
      <View css={[variantStyles.rightContent]}>
        {isLoading ? (
          <Text text={'Loading...'} variants={['p3', 'color:neutral9', 'marginLeft:1']} />
        ) : (
          <Text text={`${results || 0} results`} variants={['p3', 'color:neutral9', 'marginLeft:1', 'noWrap']} />
        )}
      </View>
    </View>
  )
}
