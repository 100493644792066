import { codeleapWebCache } from '@codeleap/web'
import { CacheProvider } from '@emotion/react'
import { Root } from './src/Root'
import Cookies from 'js-cookie'

const HEADER_NAME = 'X-Skamper-Token'
const HEADER_QUERY_KEY = 'skamperToken'
const isDevSite = process.env.GATSBY_SITE_DEV === 'true'

export const onRouteUpdate = ({ location }) => {
  if (typeof window === 'undefined' || !location?.search || !isDevSite) return

  try {
    const params = new URLSearchParams(location.search)
    const skamperToken = params.get(HEADER_QUERY_KEY)

    if (skamperToken) {
      Cookies.set(HEADER_NAME, skamperToken)
      params.delete(HEADER_QUERY_KEY)
      const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '')
      window.history.replaceState({}, '', newUrl)
    }
  } catch (error) {
    console.error('Error processing token:', error)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={codeleapWebCache}>

      <Root>
        {element}
      </Root>
    </CacheProvider>
  )
}
