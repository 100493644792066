import { ArrayCoords, Coords, LongCoords, ShortCoords } from '@/types'
import * as Turf from '@turf/turf'

export const normalizeCoords = (coords: Coords): LongCoords => ({
  latitude: !!coords?.latitude ? coords?.latitude : coords?.lat,
  longitude: !!coords?.longitude ? coords?.longitude : coords?.lng,
})

export const toArrayCoords = (coords: Coords): ArrayCoords => {
  const { latitude, longitude } = normalizeCoords(coords)
  return [longitude, latitude]
}

export const toLoongCoords = ({
  lat,
  lng,
  ...rest
}: ShortCoords): LongCoords => ({
  latitude: lat,
  longitude: lng,
  ...rest,
})

export const toShortCoords = ({
  latitude,
  longitude,
}: LongCoords): ShortCoords => ({ lat: latitude, lng: longitude })

export const distanceTo = (from: Coords, to: Coords) => {
  return Turf.distance(toArrayCoords(from), toArrayCoords(to), {
    units: 'meters',
  })
}

export function haversineDistance(coords1: ShortCoords, coords2: ShortCoords) {
  function toRad(x) {
    return (x * Math.PI) / 180
  }

  const lat1 = coords1.lat
  const lon1 = coords1.lng
  const lat2 = coords2.lat
  const lon2 = coords2.lng

  const R = 6371 // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in kilometers

  return d
}

function boundingBoxToRadius(boundingBox: string[]) {
  if (!boundingBox) return null

  const swLat = parseFloat(boundingBox[0])
  const neLat = parseFloat(boundingBox[1])
  const swLng = parseFloat(boundingBox[2])
  const neLng = parseFloat(boundingBox[3])

  const centerLat = (swLat + neLat) / 2
  const centerLng = (swLng + neLng) / 2

  const center = { lat: centerLat, lng: centerLng }
  const corner = { lat: neLat, lng: neLng }

  const radius = haversineDistance(center, corner)

  return radius
}

export const SpatialUtils = {
  boundingBoxToRadius,
  distanceTo,
  normalizeCoords,
  toLoongCoords,
  toShortCoords,
  toArrayCoords,
  haversineDistance,
}
