import { variantProvider } from '@/app'
import { Button, View, Text } from '@/components'
import { PropsOf, useBooleanToggle } from '@codeleap/common'
import { MutableRefObject, useState } from 'react'

const RATES = [0.5, 1, 1.5, 2]

type VideoSpeedControlProps = Omit<PropsOf<typeof View>, 'variants'> & {
  videoRef: MutableRefObject<HTMLVideoElement>
}

export const VideoSpeedControl = (props: VideoSpeedControlProps) => {
  const { videoRef } = props

  const [speedTooltipVisible, toggleSpeedTooltip] = useBooleanToggle(false)
  const [currentRate, setCurrentRate] = useState(1)

  function handleSpeed(rate: number) {
    if (rate === currentRate) return
    if (videoRef.current) {
      videoRef.current.playbackRate = Math.min(rate, 2)
      setCurrentRate(rate)
    }
  }

  return (
    <View variants={['column', 'relative']}>

      {speedTooltipVisible ? (
        <View variants={['column', 'gap:1.25']} style={styles.wrapper}>
          <Text
            text='Playback speed'
            variants={['h4', 'neutral-9']}
          />

          <View variants={['gap:1.25']}>
            {RATES.map((rate, index) => {
              const isSelected = rate === currentRate
              return (
                <Button
                  key={`speed option ${rate}-${index}`}
                  debugName={`speed option ${rate}-${index}`}
                  text={`${rate}x`}
                  variants={['fitlerButton', 'rounded', isSelected && 'secondary']}
                  onPress={() => handleSpeed(rate)}
                />
              )
            })}
          </View>
        </View>
      ) : null}

      <Button
        debugName='speed control'
        text={`${currentRate}x`}
        variants={['speedControl', 'rounded']}
        style={styles.button}
        onClick={(e) => {
          e.stopPropagation()
          toggleSpeedTooltip()
        }}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    borderRadius: theme.borderRadius.smallish,
    ...theme.spacing.padding(2),
    backgroundColor: theme.colors.neutral1,
    ...theme.presets.absolute,
    right: 0,
    top: -116,
  },
  button: {
    ...theme.spacing.paddingHorizontal(1),
    height: theme.spacing.value(6),
    minWidth: theme.spacing.value(6),
    [theme.media.down('mobile')]: {
      borderRadius: theme.borderRadius.smallish,
      height: theme.spacing.value(5),
      minWidth: theme.spacing.value(5),
      ...theme.spacing.paddingHorizontal(0.5),
    },
  },
}), true)