import { useEffect } from 'react'
import { Map3DCameraProps } from '../../../components/Map3D/map-3d-types'

type UsePolylineProps = {
  course: any
  polylineRef: React.RefObject<HTMLElement>
  setViewProps: (props: Map3DCameraProps) => void
  setIsPolylineReady: (ready: boolean) => void
  setIsMapPreloaded: (ready: boolean) => void
  lastPosition: React.MutableRefObject<{ lat: number; lng: number; heading: number }>
}

export const usePolyline = ({
  course,
  polylineRef,
  setViewProps,
  setIsPolylineReady,
  setIsMapPreloaded,
  lastPosition,
}: UsePolylineProps) => {
  useEffect(() => {
    if (!course?.original_points || course.original_points.length === 0) return

    const bounds = course.original_points.reduce(
      (acc, point) => ({
        minLat: Math.min(acc.minLat, point.lat),
        maxLat: Math.max(acc.maxLat, point.lat),
        minLng: Math.min(acc.minLng, point.lng),
        maxLng: Math.max(acc.maxLng, point.lng),
      }),
      {
        minLat: course.original_points[0].lat,
        maxLat: course.original_points[0].lat,
        minLng: course.original_points[0].lng,
        maxLng: course.original_points[0].lng,
      },
    )

    const centerLat = (bounds.minLat + bounds.maxLat) / 2
    const centerLng = (bounds.minLng + bounds.maxLng) / 2

    setViewProps({
      center: {
        lat: centerLat,
        lng: centerLng,
        altitude: 1000,
      },
      range: 3000,
      heading: 0,
      tilt: 45,
      roll: 0,
    })

    const initPolyline = async () => {
      try {
        await customElements.whenDefined('gmp-polyline-3d')
        await customElements.whenDefined('gmp-map-3d')

        await new Promise(resolve => setTimeout(resolve, 5000))

        if (polylineRef.current) {
          const coordinates = course.original_points.map(point => ({
            lat: point.lat,
            lng: point.lng,
            altitude: (point.elevation || 0) + 5,
          }));
          (polylineRef.current as any).coordinates = coordinates
          setIsPolylineReady(true)
        }

        const firstPoint = course.original_points[0]
        const secondPoint = course.original_points[1]
        const initialHeading = Math.atan2(
          secondPoint.lng - firstPoint.lng,
          secondPoint.lat - firstPoint.lat,
        ) * (180 / Math.PI)

        setViewProps({
          center: {
            lat: firstPoint.lat,
            lng: firstPoint.lng,
            altitude: (firstPoint.elevation || 0) + 15,
          },
          range: 80,
          heading: initialHeading,
          tilt: 60,
          roll: 0,
        })

        lastPosition.current = {
          lat: firstPoint.lat,
          lng: firstPoint.lng,
          heading: initialHeading,
        }

        await new Promise(resolve => setTimeout(resolve, 2000))
        setIsMapPreloaded(true)
      } catch (error) {
        console.error('Error initializing:', error)
      }
    }

    initPolyline()
  }, [course])
}
