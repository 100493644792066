import { AppIcon, React } from '@/app'
import { View, Button, OptionsDropDown } from '@/components'
import { PropsOf, StylesOf, useEffect, useRef } from '@codeleap/common'
import { Navigation, RoutePath, getCategoriesTree, toggleBodyScroll, useHeaderCategories } from '@/utils'
import { HeaderComposition } from '../../app/stylesheets/Header'
import { NavigationCategory } from '@/types'

export type NavContentProps = {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
  openDropdownId: number | null
  setOpenDropdownId: (id: number | null) => void
}

type ButtonProps = PropsOf<typeof Button>

const buttons: ButtonProps[] = [{
  debugName: 'Download Skamper button',
  text: 'Download Skamper for free',
  onPress: Navigation.General.navigateToStore,
  variants: ['alignSelfCenter'],
}]

export const NavContent = (props: NavContentProps) => {
  const { variantStyles, isMobile, openDropdownId, setOpenDropdownId } = props
  const categories = useHeaderCategories()
  const headerRef = useRef<HTMLDivElement>(null)

  const handleDropdownClick = (categoryId: number) => {
    setOpenDropdownId((prevId: number | null) => {
      const newId = prevId === categoryId ? null : categoryId
      toggleBodyScroll(newId !== null)
      return newId
    })
  }

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const tooltipContent = document.querySelector('[data-radix-popper-content-wrapper]')
    const isInsideTooltip = tooltipContent?.contains(target)
    const isInsideHeader = headerRef.current?.contains(target)

    if (!isInsideTooltip && !isInsideHeader && !isMobile) {
      setTimeout(() => {
        setOpenDropdownId(null)
        toggleBodyScroll(false)
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      toggleBodyScroll(false)
    }
  }, [])

  const categoriesTree = getCategoriesTree(categories || [], 3)

  const renderDropdownOptions = (category: NavigationCategory) => {

    return {
      header: category.title,
      items: (category.children || []).map(subCategory => ({
        alt: subCategory.alt,
        title: subCategory.title,
        debugName: `header-dropdown-${subCategory.title}`,
        link: subCategory.link as RoutePath,
      })),
    }
  }

  return (
    <View component='nav' css={variantStyles.navContentWrapper} ref={headerRef}>
      <View variants={['gap:2']}>
        {categoriesTree?.map((category) => (
          <OptionsDropDown
            key={category.id}
            debugName={`HeaderDropdown-${category.title}`}
            variants={[
              'optionLines:none',
              'textIcon:reverse',
              'textIcon:spacing1',
              'optionNormalize',
              'textIconBgHover:primary1',
              'textIconPadding:1',
              'textIconRadius:smallish',
              'text:neutral9',
              'optionTextHover:primary3',
            ]}
            tooltipProps={{
              closeOnClickOutside: false,
              open: openDropdownId === category.id,
              onPress: () => handleDropdownClick(category.id),
              openOnHover: false,
              openOnPress: true,
            }}
            style={{ width: 'auto' }}
            text={category.title}
            icon={openDropdownId === category.id ? 'chevron-up' as AppIcon : 'chevron-down' as AppIcon}
            options={category.children?.map(renderDropdownOptions) || []}
            id={`header-dropdown-${category.id}`}
          />
        ))}
        {buttons?.map((button, index) => (
          <Button key={`${button.text}: ${index}`} {...button} />
        ))}
      </View>
    </View>
  )
}

