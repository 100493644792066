import { useMapsLibrary } from '@vis.gl/react-google-maps'
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useMap3DCameraEvents } from './use-map-3d-camera-events'
import { useCallbackRef, useDeepCompareEffect } from '@/utils'

import './map-3d-types'

export type Map3DProps = {
  center: google.maps.LatLngAltitudeLiteral
  range: number
  heading: number
  tilt: number
  roll: number
  onCameraChange?: (cameraProps: Map3DCameraProps) => void
  children?: React.ReactNode
  defaultLabelsDisabled?: boolean
}

export type Map3DCameraProps = {
  center: google.maps.LatLngAltitudeLiteral
  range: number
  heading: number
  tilt: number
  roll: number
}

// Define a more specific return type for the component
type Map3DReturnType = React.ReactElement<any, any> | null

export const Map3D = forwardRef<google.maps.maps3d.Map3DElement, Map3DProps>(
  function Map3D(
    props: Map3DProps,
    forwardedRef: ForwardedRef<google.maps.maps3d.Map3DElement>,
  ): Map3DReturnType {
    useMapsLibrary('maps3d')

    const [map3DElement, map3dRef] =
      useCallbackRef<google.maps.maps3d.Map3DElement>()

    useMap3DCameraEvents(map3DElement, p => {
      if (!props.onCameraChange) return
      props.onCameraChange(p)
    })

    const [customElementsReady, setCustomElementsReady] = useState(false)
    useEffect(() => {
      customElements.whenDefined('gmp-map-3d').then(() => {
        setCustomElementsReady(true)
      })
    }, [])

    useDeepCompareEffect(() => {
      if (!map3DElement) return

      if (props.defaultLabelsDisabled !== undefined) {
        map3DElement.defaultLabelsDisabled = props.defaultLabelsDisabled
      }
    }, [map3DElement, props.defaultLabelsDisabled])

    useImperativeHandle<
      google.maps.maps3d.Map3DElement | null,
      google.maps.maps3d.Map3DElement | null
    >(forwardedRef, () => map3DElement, [map3DElement])

    const centerString = useMemo(() => {
      const lat = props.center?.lat ?? 0.0
      const lng = props.center?.lng ?? 0.0
      const altitude = props.center?.altitude ?? 0.0

      return [lat, lng, altitude].join(',')
    }, [props.center?.lat, props.center?.lng, props.center?.altitude])

    useEffect(() => {
      if (!map3DElement || !props.children) return

      const elements = React.Children.toArray(props.children)
      elements.forEach(element => {
        if (element && typeof (element as any).setMap === 'function') {
          (element as any).setMap(map3DElement)
        }
      })

      return () => {
        elements.forEach(element => {
          if (element && typeof (element as any).setMap === 'function') {
            (element as any).setMap(null)
          }
        })
      }
    }, [map3DElement, props.children])

    if (!customElementsReady) {
      return <div />
    }

    return (
      <gmp-map-3d
        ref={map3dRef}
        center={centerString}
        range={String(props.range)}
        heading={String(props.heading)}
        tilt={String(props.tilt)}
        roll={String(props.roll)}
      >
        {props.children}
      </gmp-map-3d>
    )
  },
) as React.ForwardRefExoticComponent<
  Map3DProps & React.RefAttributes<google.maps.maps3d.Map3DElement>
>

Map3D.displayName = 'Map3D'
