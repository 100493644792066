import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
} from 'react'
import isDeepEqual from 'fast-deep-equal'

export function useDeepCompareEffect(
  effect: EffectCallback,
  deps: DependencyList,
) {
  const ref = useRef<DependencyList | undefined>(undefined)

  if (!ref.current || !isDeepEqual(deps, ref.current)) {
    ref.current = deps
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, ref.current)
}
