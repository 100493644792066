import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { IS_SSR } from '@/app'
import { BreadcrumbsUtils, removeLeadingSlash } from '@/utils'

const isDevSite = process.env.GATSBY_SITE_DEV === 'true'

export type SEOProps = {
  title: string
  description: string
  image: string
  pathname: string
  article: string
  author: string
  doNotTrack: boolean
  appendNameToPageTitle?: boolean
  script?: string
}

const Settings = {
  site_name: 'Skamper.com',
  application_name: 'Skamper',
  revisit_after: '5 days',
  image_logo: {
    alt: 'Skamper Logo',
    width: '256',
    height: '256',
  },
  static_infos: {
    locality: 'London, GB',
    country_name: 'GB',
    copyright: '©2024 Skamper Ltd.',
    classification: 'Business',
    email: 'info@skamper.com',
  },
}

const SEO: React.FC<Partial<SEOProps>> = ({ title, description, image, pathname, article, author, doNotTrack, script }) => {
  const data = useStaticQuery(query).site.siteMetadata
  const _pathname = pathname ?? (IS_SSR ? '' : window.location.pathname)

  const seo = {
    title: title ?? data.defaultTitle,
    description: description ?? data.defaultDescription,
    image: image || `${data.siteUrl}${removeLeadingSlash(data.defaultImage)}`,
    url: `${data.siteUrl}${removeLeadingSlash(_pathname)}`,
    doNotTrack: (isDevSite || doNotTrack) ?? false,
    type: article ? 'article' : 'website',
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={data.titleTemplate}
      link={[{ rel: 'shortcut icon', type: 'image/png', href: '/favicon.png' }]}
    >
      {/* Technical stuff */}
      {/* <html lang='en' amp={undefined}/> */}
      <html lang='en' />
      <meta charSet='utf-8' />
      <body className='root' />
      {/* <script async src='https://cdn.ampproject.org/v0.js' type='text/javascript'></script> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
                (function () {
                  var e,i=["https://fastbase.com/fscript.js","FuE8ePYVL7","script"],a=document,s=a.createElement(i[2]);
                  s.async=!0,s.id=i[1],s.src=i[0],(e=a.getElementsByTagName(i[2])[0]).parentNode.insertBefore(s,e)
                })();`,
        }}
      />
      <meta name='application-name' content={Settings.application_name} />
      <meta name='revisit-after' content={Settings.revisit_after} />
      {/* <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' /> */}
      {/* <meta httpEquiv='expires' content='43200' /> */}

      {/* TODO i18n props */}
      {/* <meta property='og:locale' content='en_GB' />
      <meta name='language' content='English' />
      <meta name='coverage' content='Worldwide' /> */}

      <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
      <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no' />

      {/* Favicon */}
      <link rel='icon' type='image/png' href='/favicon.png' />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />

      <link rel="icon" href="/favicon.ico" type="image/x-icon" />

      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

      <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
      <link rel="icon" type="image/png" sizes="512x512" href="/android-chrome-512x512.png" />


      {/* Fonts */}
      <link href='https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap' rel='stylesheet' />

      <link rel='stylesheet' type='text/css' charSet='UTF-8' href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css' />
      <link rel='stylesheet' type='text/css' href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css' />

      {/* SEO */}
      <meta name='robots' content={seo.doNotTrack ? 'noindex,nofollow' : 'index,follow'} />
      <meta name='googlebot' content={isDevSite || seo?.doNotTrack ? 'noindex,nofollow' : 'index,follow'} />
      <meta name='title' content={seo.title} />
      <meta name='description' content={seo.description} />
      <meta name='identifier-URL' content={seo.url} />
      <meta name='image' content={seo.image} />
      <link rel='canonical' href={seo.url} />

      <meta name='og:site_name' content={Settings.site_name} />
      <meta property='title' content={seo.title} />
      <meta property='pagename' content={seo.title} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:type' content={seo.type} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:image' content={seo.image} />
      <meta property='og:image:secure_url' content={seo.image} />
      <meta property='og:image:alt' content={Settings.image_logo.alt} />
      <meta property='og:image:height' content={Settings.image_logo.height} />
      <meta property='og:image:width' content={Settings.image_logo.width} />

      {/* Blog articles */}
      {author && <meta name='author' content={author} />}
      {author && <meta property='article:author' content={author} />}
      {article && <meta property='article:section' content={'Software development & technology'} />}

      {/* Social */}
      <meta name='twitter:card' content='summary_large_image' />
      {data.twitterUsername && <meta name='twitter:creator' content={data.twitterUsername} />}
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />

      {/* Additional static SEO */}
      <meta name='og:locality' content={Settings.static_infos.locality} />
      <meta name='og:country-name' content={Settings.static_infos.country_name} />
      <meta name='copyright' content={Settings.static_infos.copyright} />
      <meta name='Classification' content={Settings.static_infos.classification} />
      <meta name='og:email' content={Settings.static_infos.email} />
      <meta name='theme-color' content='#262AC5' />

      {!!script && (
        <script type='application/ld+json'>
          {script}
        </script>
      )}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
