import React from 'react'
import { StylesOf, useBooleanToggle, getNestedStylesByKey, PropsOf } from '@codeleap/common'
import { HeaderComposition } from '../../app/stylesheets/Header'
import { ActionIcon, Button, Drawer, Link, Logo } from '@/components'
import { MobileMenu } from './MobileMenu'
import { getCategoriesTree, useHeaderCategories } from '@/utils'

export type DrawerMenuProps = Partial<PropsOf<typeof Drawer>> & {
  variantStyles?: StylesOf<HeaderComposition>
}

export const DrawerMenu = (props: DrawerMenuProps) => {
  const { variantStyles, ...rest } = props

  const [drawerOpen, toggleDrawer] = useBooleanToggle(false)

  const menuIconStyles = getNestedStylesByKey('menuIcon', variantStyles)
  const drawerStyles = getNestedStylesByKey('drawer', variantStyles)

  const categories = useHeaderCategories()
  const categoriesTree = getCategoriesTree(categories || [], 3)

  return <>
    <Drawer
      styles={drawerStyles}
      debugName='header:drawer'
      open={drawerOpen}
      size='75vw'
      position='top'
      toggle={toggleDrawer}
      showCloseButton
      variants={['header']}
      {...rest}
    >
      <Link route='Home' css={variantStyles.drawerLogo}>
        <Logo debugName='header:logo' style={variantStyles.logo} />
      </Link>

      <MobileMenu visible={drawerOpen} categoriesTree={categoriesTree} />
    </Drawer>

    <ActionIcon
      debugName='header:menuButton'
      icon='menu'
      styles={menuIconStyles}
      onPress={toggleDrawer}
    />
  </>
}
