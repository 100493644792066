import { View, Text } from '@/components'
import { PlaceRow, PlaceRowProps } from './PlaceRow'
import { PropsOf } from '@codeleap/common'
import { variantProvider } from '@/app'
import { EmptyPlaceholder } from '@codeleap/web'
import { SearchPrediction } from '@/types'

export type PlacesListProps = Partial<PropsOf<typeof View>> & {
  places: SearchPrediction[]
  onItemPress?: PlaceRowProps['onPress']
  label?: string
  emptyPlaceholderProps?: Partial<PropsOf<typeof EmptyPlaceholder>>
  noItemTopBorder?: boolean
  noItemBottomBorder?: boolean
}

export const PlacesList = ({
  places = [],
  noItemTopBorder = false,
  noItemBottomBorder = false,
  emptyPlaceholderProps,
  label,
  onItemPress,
  ...rest }: PlacesListProps) => {

  if (!places.length) {
    return !!emptyPlaceholderProps ? (
      <EmptyPlaceholder
        variants={['placesAutoComplete' as any]}
        {...emptyPlaceholderProps}
      />
    ) : null
  }

  return (
    <View variants={['column']} {...rest}>
      {!!label && <Text variants={['h4', 'neutral-9', 'margin:2', 'marginBottom:1']} text={label} />}
      {places?.map((place, index) => {
        const isFirst = index === 0
        const isLast = index === places.length - 1

        return (
          <PlaceRow
            key={place?.id}
            place={place}
            onPress={() => onItemPress(place, place?.type)}
            styles={{
              touchableWrapper: {
                ...(isFirst && !noItemTopBorder ? styles.borderTopRadius : {}),
                ...(isLast && !noItemBottomBorder ? styles.borderBottomRadius : {}),
              },
            }}
          />)
      })}
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  borderTopRadius: {
    borderTopLeftRadius: theme.borderRadius.medium,
  },
  borderBottomRadius: {
    borderBottomLeftRadius: theme.borderRadius.medium,
  },
}), true)
