import { assignTextStyle, ButtonComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

export type AccordionComposition =
  | 'wrapper'
  | 'header'
  | 'content'
  | 'headerText'
  | 'headerRightIcon'

const createAccordionStyle =
  variantProvider.createVariantFactory<AccordionComposition>()

export const AccordionStyles = {
  default: createAccordionStyle(theme => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      width: '100%',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      height: theme.values.itemHeight.default,
      backgroundColor: theme.colors.neutral1,
      borderRadius: 0,
      ...theme.presets.justifyBetween,
      ...theme.presets.alignCenter,
      paddingLeft: 0,
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.colors.neutral4}`,
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    headerText: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral9,
    },
    headerRightIcon: {
      color: theme.colors.neutral6,
    },
    content: {
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.paddingVertical(2),
      ...theme.presets.column,
      gap: theme.spacing.value(2),
    },
  })),
}
