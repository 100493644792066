import { variantProvider } from '@/app'
import { View, Button, Link, Accordion, Text, Scroll } from '@/components'
import { PropsOf, useEffect, useState } from '@codeleap/common'
import { Navigation, RoutePath, toggleBodyScroll } from '@/utils'

type ButtonProps = PropsOf<typeof Button>

const buttons: ButtonProps[] = [{
  debugName: 'Download Skamper button',
  text: 'Download Skamper for free',
  onPress: Navigation.General.navigateToStore,
  variants: ['alignSelfCenter'],
}]

export const MobileMenu = ({ visible, categoriesTree }) => {
  const [openCategory, setOpenCategory] = useState<number | null>(0)

  useEffect(() => {
    toggleBodyScroll(visible)
    return () => {
      toggleBodyScroll(false)
    }
  }, [visible])

  return (
    <Scroll variants={['column', 'fullWidth']} style={styles.wrapper}>
      {categoriesTree?.map((category, index) => {
        const isOpened = openCategory === index

        return (
          <Accordion
            visible={isOpened}
            onPress={() => {
              setOpenCategory(isOpened ? null : index)
            }}
            key={category.id}
            title={category.title}
          >
            {category.children?.map(subCategory => (
              <View key={subCategory.id} variants={['column', 'gap:2', 'column']}>
                <Text
                  text={subCategory.title}
                  variants={['p3', 'neutral-7']}
                />
                {subCategory.children?.map(item => (
                  <Link
                    key={item?.id}
                    about={item?.alt}
                    route={item?.link as RoutePath}
                    text={item?.title}
                    to={'www.google.com'}
                    css={styles.navItemMobile}
                  />
                ))}
              </View>
            ))}

          </Accordion>
        )
      })}

      {buttons?.map((button, index) => (
        <Button key={`${button.text}: ${index}`} {...button} variants={['marginTop:2']} />
      ))}
    </Scroll>
  )
}
const HEADER_HEIGHT = 42

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    maxHeight: `calc(100svh - ${HEADER_HEIGHT + theme.spacing.value(2)}px)`,
    height: `calc(100svh - ${HEADER_HEIGHT + theme.spacing.value(2)}px)`,
    ...theme.spacing.paddingBottom(2),
    overflow: 'auto',
  },
  navItemMobile: {
    color: theme.colors.neutral9,
    textDecoration: 'none',
    '&:hover': {
      color: theme.colors.primary3,
      textDecoration: 'underline',
    },
  },
}), true)
