import { Course } from '@/types'
import _course from './course.json'
import _area from './area.json'
import { Testimonials } from './testimonials'
import { LatestPosts } from './latestPosts'

export const Mocks = {
  course: _course as unknown as Course,
  testimonials: Testimonials,
  latestPosts: LatestPosts,
  area: _area,
}
