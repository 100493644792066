import { useEffect } from 'react'
import { Map3DCameraProps } from '@/components'
import { haversineDistance, lerp } from '@/utils'

type UseCameraAnimationProps = {
  course: any
  isMapPreloaded: boolean
  isPolylineReady: boolean
  setIsAnimationComplete: (complete: boolean) => void
  lastPosition: React.MutableRefObject<{ lat: number; lng: number; heading: number }>
  setViewProps: (props: Map3DCameraProps) => void
  animationRef: React.MutableRefObject<number>
}

export const useCameraAnimation = ({
  course,
  isMapPreloaded,
  isPolylineReady,
  setIsAnimationComplete,
  lastPosition,
  setViewProps,
  animationRef,
}: UseCameraAnimationProps) => {
  useEffect(() => {
    if (!course?.original_points || !isMapPreloaded || !isPolylineReady) return

    let pathLength = 0
    for (let i = 0; i < course.original_points.length - 1; i++) {
      const current = course.original_points[i]
      const next = course.original_points[i + 1]
      pathLength += haversineDistance(
        { lat: current.lat, lng: current.lng },
        { lat: next.lat, lng: next.lng },
      ) * 1000
    }

    let startTime: number | null = null
    const baseSpeed = 35
    let lastFrameTime = 0
    let lastHeading = 0
    let animationStarted = false

    const animate = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp
        lastFrameTime = timestamp
      }

      if (!animationStarted) {
        animationStarted = true
      }

      const deltaTime = timestamp - lastFrameTime
      lastFrameTime = timestamp

      const elapsedTime = timestamp - startTime
      const distanceTraveled = (baseSpeed * elapsedTime) / 1000

      if (distanceTraveled >= pathLength) {
        setIsAnimationComplete(true)
        const stopMarker = document.createElement('div')
        stopMarker.setAttribute('data-testid', 'map-animation-complete')
        stopMarker.style.display = 'block'
        document.body.appendChild(stopMarker)
        return
      }

      let accumulatedDistance = 0
      let currentIndex = 0
      let segmentProgress = 0

      for (let i = 0; i < course.original_points.length - 1; i++) {
        const current = course.original_points[i]
        const next = course.original_points[i + 1]
        const segmentDistance = haversineDistance(
          { lat: current.lat, lng: current.lng },
          { lat: next.lat, lng: next.lng },
        ) * 1000

        if (accumulatedDistance + segmentDistance >= distanceTraveled) {
          currentIndex = i
          segmentProgress = (distanceTraveled - accumulatedDistance) / segmentDistance
          break
        }
        accumulatedDistance += segmentDistance
      }

      const current = course.original_points[currentIndex]
      const next = course.original_points[currentIndex + 1]

      const newLat = lerp(current.lat, next.lat, segmentProgress)
      const newLng = lerp(current.lng, next.lng, segmentProgress)
      const newElevation = lerp(
        current.elevation || 0,
        next.elevation || 0,
        segmentProgress,
      )

      const lookAheadIndex = Math.min(currentIndex + 1, course.original_points.length - 1)
      const lookAheadPoint = course.original_points[lookAheadIndex]

      const targetHeading = Math.atan2(
        lookAheadPoint.lng - current.lng,
        lookAheadPoint.lat - current.lat,
      ) * (180 / Math.PI)

      const headingDiff = targetHeading - lastHeading
      const normalizedDiff = ((headingDiff + 180) % 360) - 180
      const smoothHeading = lastHeading + (normalizedDiff * deltaTime * 0.001)

      const cameraHeight = newElevation + 15

      setViewProps({
        center: {
          lat: newLat,
          lng: newLng,
          altitude: cameraHeight,
        },
        heading: smoothHeading,
        range: 80,
        tilt: 60,
        roll: 0,
      })

      lastPosition.current = {
        lat: newLat,
        lng: newLng,
        heading: smoothHeading,
      }
      lastHeading = smoothHeading

      animationRef.current = requestAnimationFrame(animate)
    }

    animationRef.current = requestAnimationFrame(animate)

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [course, isMapPreloaded, isPolylineReady])
}
